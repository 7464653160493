@font-face {
  font-family: 'lemon';
  src: url('/fonts/icomoon.eot?61tg2y');
  src: url('/fonts/icomoon.eot?61tg2y#iefix') format('embedded-opentype'),
  url('/fonts/icomoon.ttf?61tg2y') format('truetype'),
  url('/fonts/icomoon.woff?61tg2y') format('woff'),
  url('/fonts/icomoon.svg?61tg2y#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'lemon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-arrow{
  &:before {
    content: "\e900";
  }
  &.right {
    transform: rotate(90deg);
  }
  &.left {
    transform: rotate(-90deg);
  }
}
.icon-cross:before {
  content: "\e901";
}
.icon-email:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\e903";
}
.icon-gps:before {
  content: "\e904";
}
.icon-instagram:before {
  content: "\e905";
}
.icon-profile:before {
  content: "\e906";
}
.icon-write:before {
  content: "\e907";
}
.icon-youtube:before {
  content: "\e908";
}
.icon-x:before {
  content: "\e909";
}
.icon-tick:before {
  content: "\e90a";
}
.icon-vimeo:before {
  content: "\eaa0";
}
