.contact-form-wrapper {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: white;
  -webkit-overflow-scrolling: touch;
  transition: all .5s ease-in-out;
  @media(max-width: 767px) {
    top: 98px;
    height: auto;
    bottom: 0;
  }
  @media(max-width: 480px) {
    top: 66px;
    height: auto;
    bottom: 0;
    background: white;
  }

  .container {
    height: 100%;
  }

  .intro-loader {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9 !important;
    min-height: auto;
  }

  .logo-place {
    position: absolute;
    left: 15px;
    top: 30px;
    width: 265px;
    display: flex;
    align-items: center;
    opacity: 0;
    animation: showLogo .3s ease-out forwards;
    @media(max-width: 767px) {
      display: none;
    }

    img {
      max-width: 100%;
    }
  }

  .modal-close-button {
    position: absolute;
    z-index: 999;
    background: transparent;
    border: 0;
    right: 15px;
    top: 30px;
    font-size: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    @media(max-width: 767px) {
      display: none;
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 13px;
      animation: fadeIn .3s ease-in-out forwards;
      opacity: 0;
    }

    svg {
      height: 53px;
      width: 53px;

      circle {
        fill: transparent;
        stroke-width: 2px;
        stroke: $black;
        transform: translate(1px, 1px) rotate(-90deg);
        animation: circle-animation 6s ease-out forwards;
        transform-origin: 25px 25px;
      }
    }

    &:focus {
      outline: none;
    }
  }

  .contact-flex-row {
    display: flex;
    height: 100%;
    @media(max-width: 767px) {
      flex-wrap: wrap;
    }


    .form-col {
      width: 65%;
      display: flex;
      margin: auto;
      position: relative;
      @media(max-width: 767px) {
        width: 100%;
        margin: 0;
        padding: 30px 0 120px;
      }

      h1 {
        font-size: 64px;
        letter-spacing: -3.2px;
        margin-bottom: 60px;
        line-height: 80px;
        @media(max-width: 1200px) {
          font-size: 50px;
        }
        @media(max-width: 767px) {
          font-size: 28px;
          letter-spacing: -1.4px;
          line-height: 28px;
        }
        @media(max-width: 767px) {
          margin-bottom: 32px;
        }

        span {
          font-weight: 200;
        }
      }

      .form-wrapper {
        margin-top: 50px;
        width: 100%;
        padding-right: 130px;
        animation: contactFormSlideRight .5s cubic-bezier(0.165, 0.84, 0.44, 1) 1.1s forwards;
        opacity: 0;
        @media(max-width: 991px) {
          padding-right: 30px;
        }
        @media(max-width: 767px) {
          padding-right: 0;
          margin: 0;
          width: 100%;
          animation: contactFormSlideRight .5s cubic-bezier(0.165, 0.84, 0.44, 1) .5s forwards;
        }

        .half-width-inputs {
          display: flex;
          align-items: center;
          margin: 0 -7px;
          @media(max-width: 480px) {
            flex-wrap: wrap;
            margin: 0;
          }

          .form-group {
            width: 50%;
            padding: 0 7px;
            @media(max-width: 480px) {
              width: 100%;
              padding: 0;
              margin-bottom: 32px;
            }
          }
        }

        .submit-btn {
          border: 0;
          color: $black;
          background: $yellow;
          padding: 18px 58px;
          display: flex;
          margin-left: auto;
          font-weight: 500;
          margin-top: 60px;
          @media(max-width: 480px) {
            padding: 16px 43px;
            font-size: 12.8px;
            font-weight: 500;
            letter-spacing: -0.4px;
            line-height: 15px;
            margin-top: 35px;
            border-radius: 8px;
          }
        }
      }
    }

    .address-col {
      width: 35%;
      display: flex;
      margin: auto 0 auto auto;
      padding-left: 65px;
      z-index: 2;
      position: relative;
      transition: all .3s ease-in-out .7s;
      @media(max-width: 991px) {
        padding-left: 30px;
      }
      @media(max-width: 767px) {
        width: 100%;
        padding-left: 0;
        margin: 0;
        padding-top: 60px;
        padding-bottom: 60px;
        &:after {
          content: "";
          position: absolute;
          left: -130px;
          right: -130px;
          bottom: 0;
          background: $yellow;
          z-index: -1;
          height: 0;
          animation: yellowStripSlideDown .3s ease-in-out .7s forwards;
        }
      }

      .address {
        position: relative;
        z-index: 1;
        opacity: 0;
        animation: fadeIn .5s ease-in-out 1.7s forwards;
        width: 270px;
        @media(max-width: 767px) {
          width: 100%;
          animation: fadeIn .5s ease-in-out 1s forwards;
        }

        .title {
          font-size: 12px;
          line-height: 25px;
          font-weight: 400;
          margin-bottom: 3px;
          color: $black;
        }
      }
    }

    .info-msg {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      font-size: 30px;
      opacity: 0;
      animation: slideDown .5s cubic-bezier(0.165, 0.84, 0.44, 1) .8s forwards;

      &.success {
        display: flex;
        align-items: flex-start;
        width: 400px;
        justify-content: center;
        @media(max-width: 480px) {
          width: 100%;
          padding: 0 15px;
        }

        .icon-column {
          position: relative;

          img {
            width: 60px;
          }

          i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;

            &:after {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              background: white;
              animation: iconDraw .4s cubic-bezier(0.165, 0.84, 0.44, 1) 1.3s forwards;
              @media(max-width: 767px){
                animation: iconDraw .4s cubic-bezier(0.165, 0.84, 0.44, 1) 6s forwards;
              }
            }
          }
        }

        .content-column {
          margin-left: 20px;
          text-align: left;

          h1 {
            line-height: 52px;
            font-size: 40px;
            font-weight: 600;
            letter-spacing: -2.4px;
            margin-bottom: 15px;
            @media(max-width: 480px) {
              font-size: 30px;
              margin-bottom: 10px;
            }
          }

          div {
            font-size: 26px;
            font-weight: 300;
            letter-spacing: -1.56px;
            line-height: 36px;
            margin-bottom: 15px;
            @media(max-width: 480px) {
              font-size: 20px;
              line-height: 28px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &.closing {
    left: 100%;
    @media (max-width: 767px) {
      transition: all .5s ease-in-out .5s;
      opacity: 1;
      animation: slideRight .5s cubic-bezier(0.165, 0.84, 0.44, 1) .5s forwards;
    }

    .contact-flex-row {
      .form-col {
        .form-wrapper {
          @media(max-width: 767px) {
            animation: contactFormSlideRightOut .5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
          }
        }
      }

      .address-col {
        @media(max-width: 767px) {
          animation: contactFormSlideRightOut .5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }
      }
    }

    .info-msg {
      @media(max-width: 767px) {
        animation: infoMsgSlideRight .5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      }
    }
  }

  &.form-sent {
    .address-col {
      opacity: 0;
      transition: all .3s ease-in-out;
      @media (max-width: 767px) {
        transition: none;
        animation: none!important;
      }
    }
    .info-msg {
      @media(max-width: 767px) {
        animation: slideDown .5s cubic-bezier(0.165, 0.84, 0.44, 1) .3s forwards;
      }
    }

    &:after {
      left: 100%;
    }
    &.closing{
      @media (max-width: 767px) {
        animation: none;
      }

      .info-msg {
        @media(max-width: 767px) {
          animation: infoMsgSlideRight .5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }
      }
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: calc(50% + 188px);
    right: 0;
    top: 0;
    bottom: 0;
    background: $yellow;
    height: 0;
    animation: yellowStripSlideUp .8s .3s forwards;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all .7s ease-in-out .3s;
    @media(max-width: 1250px) {
      left: calc(50% + 166px);
    }
    @media(max-width: 1200px) {
      left: calc(50% + 141px);
    }
    @media(max-width: 991px) {
      left: calc(50% + 104px);
    }
    @media(max-width: 767px) {
      display: none;
    }
  }
}

.contact-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1;
  @media(max-width: 767px) {
    display: none;
  }
}

.contact-form-overlay, .contact-form-wrapper {
  animation: modalShow linear .3s;
  @media(max-width: 767px) {
    animation: slideIn .5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
}


@keyframes modalShow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1
  }
}

@keyframes yellowStripSlideUp {
  0% {
    height: 0;
  }

  100% {
    height: 100%;
  }
}

@keyframes contactFormSlideRight {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes contactFormSlideRightOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(50px);
  }
}
@keyframes infoMsgSlideRight {
  0% {
    opacity: 1;
    margin-left: 0;
  }

  100% {
    opacity: 0;
    margin-left: 50px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    margin-top: -60px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes circle-animation {
  0% {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes yellowStripSlideDown {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}


@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes iconDraw {
  0% {
    left: 0;
  }

  100% {
    left: 100%;
  }
}

@keyframes infoMsgSlideRight {
  0% {
    opacity: 1;
    margin-left: 0;
  }
  100% {
    margin-left: 40px;
    opacity: 0;
  }
}

@keyframes showLogo {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
