header {
  height: 98px;
  @media(max-width: 480px) {
    height: 66px;
  }

  .header {
    padding: 32px 0;
    z-index: 13;
    background: #fff;
    left: 0;
    position: fixed;
    top: 0;
    @media (max-width: 1480px) {
      padding: 26px 0;
    }
    @media(max-width: 991px) {
      left: 0!important;
      right: 0!important;
    }
    @media(max-width: 767px) {
      padding: 16px 0;
    }

    nav {
      display: flex;
      align-items: center;

      .logo-place {
        width: 265px;
        display: flex;
        align-items: center;
        @media(max-width: 767px) {
          z-index: 11;
        }
        @media(max-width: 480px) {
          width: 190px;
        }

        img {
          max-width: 100%;
        }
      }

      ul {
        margin: 0 0 0 auto;
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        @media(max-width: 767px) {
          position: fixed;
          top: 63px;
          left: 0;
          display: flex;
          flex-direction: column;
          height: calc(100% - 63px);
          overflow-y: auto;
          bottom: 0;
          width: 100%;
          background: white;
          z-index: 10;
          pointer-events: none;
          transform: translateX(-100%);
        }

        li {
          padding: 0 20px;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: -0.2px;
          line-height: 18px;
          @media(max-width: 767px) {
            max-width: 540px;
            margin: 0 auto;
            width: 100%;
          }
          @media(max-width: 575px) {
            max-width: 100%;
          }

          a {
            color: $black;
            text-decoration: none;
            @media(max-width: 767px) {
              align-items: flex-start;
            }

            &:hover {
              color: $black;
            }
            &.active{
              span{
                font-weight: 600;
              }
            }
          }

          .contact-button {
            padding: 14px 18px;
            font-weight: inherit;
            border: 0;
            border-radius: 10px;
            background-color: $lemon;
          }

          &.header-contact-btn {
            padding-left: 38px;
            padding-right: 18px;
          }
        }
      }

      .mobile-contact-data {
        z-index: 11;
        margin-top: auto;

        & > div {
          border-top: 1px solid $border;
          padding: 14px 0;
        }

        .social-box {
          a {
            vertical-align: middle;
            font-size: 26px;
            line-height: 26px;
            margin-right: 15px;
            @media(min-height: 668px) {
              display: block;
              margin-right: 0;
              margin-top: 20px;
              text-shadow: none;
              span {
                display: inline-block;
                color: $black;
                -webkit-text-stroke: 1px rgba(30, 32, 38, 0.5);
                position: relative;
                font-size: 30px;
                line-height: 30px;

                &:after {
                  content: attr(data-text);
                  top: 0;
                  left: 0;
                  width: 0;
                  color: white;
                  overflow: hidden;
                  position: absolute;
                  transition: all 0.9s;
                }
              }
              &:first-of-type {
                margin-top: 10px;
              }
              i {
                display: none;
              }
            }
            @media(max-height: 668px) {
              span {
                display: none;
              }
            }
            @media(max-height: 668px) {
              &:nth-of-type(2) {
                font-size: 19px;
              }
              &:last-of-type {
                font-size: 18px;
              }
            }
          }
        }

        .label {
          font-size: 10px;
          font-weight: 500;
          letter-spacing: -0.5px;
          line-height: 25px;
        }

        .value, .value a {
          font-size: 14px;
          font-weight: bold;
          letter-spacing: -0.5px;
          line-height: 25px;
          display: flex;
          flex-wrap: wrap;
          @media(max-width: 480px) {
            justify-content: space-between;
          }
        }

        @media(min-width: 767px) {
          display: none;
        }
      }

      .mobile-nav {
        @media(min-width: 767px) {
          display: none;
        }
        z-index: 11;
        display: flex;
        align-items: center;
        margin-left: auto;

        .mobile-contact-btn {
          border: 0;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background: $yellow;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
          font-size: 18px;

          i {
            &:before {
              color: $black;
            }
          }
        }
      }
    }

    &.fixed {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      animation: slideDownHeader .4s forwards;
      animation-timing-function: ease-in-out;
    }

    &.hidden {
      animation: slideUpHeader .2s forwards;
      animation-timing-function: ease-in-out;
    }
  }

  &.mobile-menu {
    @media(max-width: 767px) {
      position: relative;
      z-index: 999999;
      .header {
        nav {
          ul {
            pointer-events: visible;
            animation: slideIn .5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

            li {
              opacity: 0;
              padding-top: 35px;
              animation: contactFormSlideRight .5s cubic-bezier(0.165, 0.84, 0.44, 1) .5s forwards;

              a {
                font-size: 30px;
                font-weight: bold;
                letter-spacing: -2.05px;
                line-height: 29px;

              }
              .social-box{
                a span:after {
                  animation: fillText 1s cubic-bezier(0.165, 0.84, 0.44, 1) 1s forwards;
                }
              }

              &.header-contact-btn {
                padding-top: 50px;
              }
            }
          }
        }
      }
    }
  }

  &.closing {
    @media(max-width: 767px) {
      .header {
        nav {
          ul {
            pointer-events: visible;
            opacity: 1;
            transform: translateX(0);
            animation: slideRight .5s cubic-bezier(0.165, 0.84, 0.44, 1) .5s forwards;

            li {
              padding-top: 35px;
              opacity: 1;
              animation: contactFormSlideRightOut .5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

              a {
                font-size: 30px;
                font-weight: bold;
                letter-spacing: -2.05px;
                line-height: 29px;

              }
              .social-box{
                a span:after {
                  animation: fillText 1s cubic-bezier(0.165, 0.84, 0.44, 1) 0.5s forwards;
                }
              }

              &.header-contact-btn {
                padding-top: 50px;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes slideDownHeader {
  0% {
    transform: translateY(-114px);
    @media(max-width: 767px) {
      transform: translateY(-98px);
    }
    @media(max-width: 480px) {
      transform: translateY(-66px);
    }
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideUpHeader {
  100% {
    transform: translateY(-114px);
    @media(max-width: 767px) {
      transform: translateY(-98px);
    }
    @media(max-width: 480px) {
      transform: translateY(-66px);
    }
  }
}

@keyframes fillText {
  0%{
    width: 0;
  }
  100%{
    width: 101%;
  }
}
