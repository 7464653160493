body {
  font-family: termina, sans-serif;
}

* {
  &::selection {
    background: $lemon;
  }
}

h1 {
  font-size: 38px;
  font-weight: bold;
  letter-spacing: -1.53px;
  line-height: 56px;
  color: $black;
  margin-top: 0;
  margin-bottom: 0;
  @media(max-width: 991px) {
    font-size: 30px;
    line-height: 45px;
  }
  @media(max-width: 480px) {
    font-size: 22px;
    letter-spacing: -1px;
    line-height: 28px;
  }

  span {
    font-weight: 300;
  }
}

h2 {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: -1.6px;
  line-height: 48px;
  color: $black;
  margin-top: 0;
  margin-bottom: 0;
  @media(max-width: 480px) {
    font-size: 22px;
    letter-spacing: -1px;
    line-height: 30px;
  }

  span {
    font-weight: 300;
  }
}

h3 {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -0.7px;
  line-height: 60px;
  color: $black;
  margin-top: 0;
  margin-bottom: 0;
  @media(max-width: 1480px) {
    font-size: 24px;
    line-height: 57px
  }
  @media(max-width: 480px) {
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 30px;
  }
}

h4 {
  font-size: 26px;
  font-weight: 600;
  letter-spacing: -0.43px;
  line-height: 34px;
  color: $black;
  margin-top: 0;
  margin-bottom: 0;
  @media(max-width: 480px) {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.8px;
    line-height: 24px;
  }
}

.underline-button {
  position: relative;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.6px;
  line-height: 24px;
  background: transparent;
  border: 0;
  padding: 0;
  display: inline-block;
  color: $black;
  text-decoration: none;
  @media(max-width: 991px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media(max-width: 480px) {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.36px;
    line-height: 15px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 10px);
    height: 3px;
    background: $lemon;
    width: 100%;
    pointer-events: none;
    transition: all .4s ease-in-out;
    @media(max-width: 480px) {
      height: 2px;
      top: calc(100% + 6px);
    }
  }

  &:hover {
    text-decoration: none;
    color: $black;

    &:after {
      width: 0;
    }
  }
}

.translating-button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  background: transparent;
  border: 0;
  font-weight: 500;
  vertical-align: middle;
  text-align: center;
  transition: color .4s;

  em {
    display: inline-block;
    overflow: hidden;
    font-style: normal;

    span {
      font-weight: 500;
      font-family: termina, sans-serif;
      position: relative;
      display: inline-block;
      transform: translateY(2px) translateZ(0);
      transition: transform .5s cubic-bezier(.44, .15, .2, 1);
      @media(max-width: 757px) {
        font-weight: bold;
      }

      &:after {
        content: attr(data-text);
        display: block;
        position: absolute;
        top: 130%;
        left: 0;
        transform: skewY(7deg);
        transform-origin: left center;
        transition: transform .4s;
      }
    }
  }

  @media(min-width: 767px) {
    &:hover {
      em span {
        transform: translateY(-130%) translateZ(0);

        &:after {
          transform: skewY(0);
        }
      }
    }
  }

  &:focus {
    outline: none;
  }

  &.with-bg {
    em {
      z-index: 1;

      span {
        @media(max-width: 767px) {
          font-weight: 500;
        }
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -18px;
      right: -18px;
      background: #CBE3F2;
      height: 46px;
      font-weight: inherit;
      border: 0;
      border-radius: 10px;
      transition: -webkit-transform .35s cubic-bezier(.47, 1.64, .41, .8);
      transition: transform .35s cubic-bezier(.47, 1.64, .41, .8);
    }

    @media(min-width: 767px) {
      &:hover {
        &:after {
          transform: scale(1.1) translateY(-50%);
        }
      }
    }
  }
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  font-family: termina, sans-serif;

  &:focus {
    outline: none;
  }
}
