.video-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 15;
  margin: 140px 80px 80px;
  @media(max-width: 767px){
    margin: 100px 40px 40px;
  }
  @media(max-width: 480px){
    margin: 15px 0;
  }
  .buttons-wrapper{
    height: 140px;
    margin-top: -140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 767px){
      margin-top: -100px;
    }
    @media(max-width: 480px){
      margin-top: -15px;
    }
  }

  .close-btn{
    display: flex;
    align-items: center;
    color: white;
    z-index: 12;
    cursor: pointer;
    background: transparent;
    border: 0;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0;
    margin: 0;
    opacity: 0;
    animation: fadeInButton .5s forwards .5s;
    i{
      margin-top: 1px;
      margin-left: 10px;
      font-size: 50px;
      @media(max-width: 767px){
        font-size: 30px;
      }
    }
    span{
      @media(max-width: 480px){
        display: none;
      }
    }
  }
  .logo{
    opacity: 0;
    width: 265px;
    z-index: 12;
    cursor: pointer;
    filter: invert(1);
    animation: fadeInButton .5s forwards .5s;
    @media(max-width: 480px){
      width: 180px;
    }
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


  &.active {
    opacity: 1;
    visibility: visible;

    + .video-backdrop {
      animation: fadeInVideo .5s forwards;
    }
  }
}

.video-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0;
  z-index: 14;
}

@keyframes fadeInVideo {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInButton {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
