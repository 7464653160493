.intro-loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  z-index: 9;
  min-height: 100vh;

  svg {
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    polygon {
      &:nth-of-type(-n + 6) {
        animation: lds-spinner-first 1.5s linear infinite;
        visibility: hidden;
      }

      &:nth-of-type(1) {
      }

      &:nth-of-type(2) {
        animation-delay: 1.25s;
      }

      &:nth-of-type(3) {
        animation-delay: 1s;
      }

      &:nth-of-type(4) {
        animation-delay: 0.75s;
      }

      &:nth-of-type(5) {
        animation-delay: 0.5s;
      }

      &:nth-of-type(6) {
        animation-delay: 0.25s;
      }
      &:nth-of-type(n + 7){
        visibility: hidden;
        animation: lds-spinner-second 3s linear infinite;
        opacity: 1;
      }
      &:nth-of-type(8) {
        animation-delay: 1.25s;
      }

      &:nth-of-type(9) {
        animation-delay: 1s;
      }

      &:nth-of-type(10) {
        animation-delay: 0.75s;
      }

      &:nth-of-type(11) {
        animation-delay: 0.5s;
      }

      &:nth-of-type(12) {
        animation-delay: 0.25s;
      }
    }
  }
  &.new-animation{
    polygon {
      &:nth-of-type(-n + 6) {
        visibility: hidden!important;
      }
      &:nth-of-type(n + 7){
        visibility: visible;
      }
    }
  }
}

@keyframes lds-spinner-first {
  0% {
  visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

@keyframes lds-spinner-second {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
