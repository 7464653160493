.title-section {
  h1 {
    display: inline-block;
    margin: 165px 0;
    @media(max-width: 1200px) {
      margin: 125px 0;
    }
    @media(max-width: 991px) {
      margin: 80px 0;
    }
    @media(max-width: 480px) {
      margin: 30px 0;
    }

    div {
      font-weight: 200;
    }
  }
}

.video-section {
  max-width: 1480px;
  margin: 0 auto;
  @media(max-width: 1480px) {
    max-width: 100%;
  }

  .short-video-wrapper {
    max-width: 100%;
    position: relative;
    cursor: pointer;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      transition: all .3s ease-in-out;
      @media(max-width: 480px) {
        width: 70px;
      }
    }

    video {
      max-height: calc(100vh - 90px);
      width: 100%;
      @media (max-width: 1480px) {
        max-height: calc(100vh - 86px);
      }
    }

    &:hover {
      img {
        transform: translate(-50%, -50%) scale(1.15);
      }
    }
  }

  .photo-trigger {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    @media(max-width: 767px) {
      background-attachment: unset;
    }
  }
}

.about-section {
  margin-top: 275px;
  text-align: center;
  @media(max-width: 1480px) {
    max-width: 930px;
    margin: 160px auto 0 auto;
  }
  @media(max-width: 480px) {
    margin-top: 80px;
  }

  h3 {
    font-weight: normal;
    div {
      display: inline;
      font-weight: 300;
    }
  }

  .underline-button {
    margin-top: 115px;
    @media(max-width: 991px) {
      margin-top: 70px;
    }
    @media(max-width: 480px) {
      margin-top: 30px;
    }
  }
}

.clients-section {
  margin-top: 250px;
  @media(max-width: 1480px) {
    margin-top: 90px;
  }

  h2 {
    margin-bottom: 70px;
    @media(max-width: 480px) {
      margin-bottom: 30px;
    }
  }

  .underline-button {
    margin-top: 60px;
    @media(max-width: 991px) {
      margin-top: 30px;
    }
    @media(max-width: 480px) {
      margin-top: 15px;
    }
  }

  .clients-row {
    .clients {
      position: relative;
      height: 65px;
      display: flex;
      margin-bottom: 96px;
      justify-content: space-between;
      margin-left: -15px;
      margin-right: -15px;

      @media(max-width: 1480px) {
        margin-bottom: 70px;
      }
      @media(max-width: 991px) {
        flex-wrap: wrap;
        height: auto;
        margin-bottom: 20px;
      }

      a {
        padding: 0 44px;
        text-align: center;
        width: calc(100% / 6);
        display: flex;
        align-items: center;
        justify-content: center;
        @media(max-width: 991px) {
          min-width: calc(100% / 3);
          height: 90px;
          margin-bottom: 20px;
        }
        @media(max-width: 480px) {
          padding: 0 15px;
        }

        img {
          max-width: 100%;
          max-height: 70px;
          filter: grayscale(100%);
        }
      }
    }
  }

  .button-space {
    text-align: center;
  }

  &.clients-page {
    margin: 0;

    h1 {
      padding: 110px 0;
      margin: 0;
      @media(max-width: 767px) {
        padding: 50px 0;
      }
      @media(max-width: 480px) {
        padding: 30px 0;
      }
    }
  }
}

.categories-section {
  margin-top: 300px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media(max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 130px;
  }
  @media(max-width: 480px) {
    margin-top: 70px;
  }

  .text-content {
    position: sticky;
    top: 100px;
    @media(max-width: 991px) {
      position: static;
    }

    h2 {
      margin-bottom: 16px;
    }

    .description {
      font-size: 20px;
      font-weight: 200;
      letter-spacing: -0.33px;
      line-height: 34px;
      width: 361px;
      color: $black;
      @media(max-width: 991px) {
        margin-bottom: 50px;
      }
      @media(max-width: 480px) {
        width: auto;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: -0.23px;
        line-height: 24px;
        margin-bottom: 30px;
      }
    }
  }

  .categories-wrapper {
    max-width: 700px;
    .category {
      position: relative;
      margin-bottom: 65px;
      display: block;
      text-decoration: none;
      @media(max-width: 1200px) {
        margin-bottom: 40px;
      }
      @media(max-width: 480px) {
        margin-bottom: 30px;
      }
      .video-box {
        pointer-events: none;
        position: absolute;
        top: calc(50% - 3px);
        transform: translateY(-50%);
        left: 105px;
        width: calc(100% - 90px);
        height: 0;
        overflow: hidden;
        transition: height .4s ease-in-out;
        border-radius: 5px;
        video{
          min-width: 100%;
          min-height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }
        @media(max-width: 767px){
          display: none;
        }
      }
      .category-info {
        display: inline-flex;
        @media(max-width: 480px) {
          display: block;
        }

        .number {
          min-width: 120px;
          font-size: 52px;
          line-height: 58px;
          font-weight: 600;
          color: transparent;
          text-shadow: none;
          -webkit-text-stroke: 1px rgba(30, 32, 38, 0.5);
          position: relative;
          z-index: 12;
          transition: all .4s ease-in-out;
          @media(max-width: 1200px) {
            font-size: 46px;
          }
          @media(max-width: 991px) {
            font-size: 40px;
          }
          @media(max-width: 480px) {
            font-size: 20px;
            letter-spacing: -0.83px;
            font-weight: bold;
            line-height: 20px;
          }
        }

        .name {
          font-size: 52px;
          line-height: 58px;
          letter-spacing: -0.3px;
          color: $black;
          font-weight: bold;
          position: relative;
          z-index: 12;
          @media(max-width: 1200px) {
            font-size: 46px;
            line-height: 48px;
          }
          @media(max-width: 991px) {
            font-size: 46px;
            line-height: 46px;
            margin-top: 10px;
          }
          @media(max-width: 480px) {
            font-size: 30px;
            font-weight: bold;
            letter-spacing: -1.67px;
            margin-top: 0;
          }
        }

        @media(min-width: 767px) {
          &:hover {
            .number {
              z-index: 14;
              -webkit-text-stroke: unset;
              mix-blend-mode: exclusion;
              color: white;
            }

            .name {
              z-index: 14;
              mix-blend-mode: exclusion;
              color: white;
            }
            + .video-box {
              height: 130px;
            }
          }
        }
      }

      &:hover {
        text-decoration: none;

        .number {
          z-index: 14;
          -webkit-text-stroke: unset;
          mix-blend-mode: exclusion;
          color: white;
        }

        .name {
          z-index: 14;
          mix-blend-mode: exclusion;
          color: white;
        }
      }
    }
  }
}

.realizations-section {
  margin-top: 300px;
  position: relative;
  @media(max-width: 991px) {
    margin-top: 150px;
  }
  @media(max-width: 480px) {
    margin-top: 50px;
  }

  h2 {
    margin-bottom: 70px;
    @media(max-width: 480px) {
      margin-bottom: 25px;
    }
  }
}

.realizations-wrapper {
  & > .realization {
    &:nth-of-type(2n) {
      .photo-col {
        order: 2;
        @media(max-width: 991px) {
          order: 1;
        }
      }

      .info-col {
        order: 1;
      }
    }
  }

  .realization {
    display: flex;
    align-items: center;
    margin-left: -25px;
    margin-right: -25px;
    margin-bottom: 270px;
    @media(max-width: 991px) {
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 150px;
    }
    @media(max-width: 480px) {
      margin-bottom: 90px;
    }

    .photo-col {
      width: 70%;
      padding: 0 25px;
      position: relative;
      height: 500px;
      overflow: hidden;
      @media(max-width: 991px) {
        width: 100%;
        padding: 0;
        order: 1;
      }
      @media(max-width: 767px) {
        height: 300px;
      }
      @media(max-width: 480px) {
        height: 180px;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
      }

      .photo:not(img) {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @keyframes loaded {
          0% {
            opacity: 0;
            transform: translateY(50px)
          }
          100% {
            opacity: 1;
            transform: translateY(0)
          }
        }
        @media(max-width: 767px) {
          &.loaded:not(.has-error) {
            animation: loaded 300ms ease-in-out;
          }
        }
      }
    }

    .info-col {
      width: 30%;
      padding: 0 25px;
      @media(max-width: 991px) {
        width: 100%;
        padding: 0 15px;
        order: 2;
      }
    }

    h4 {
      margin-top: 25px;
      @media(max-width: 480px) {
        margin-bottom: 5px;
      }
    }

    .description {
      font-size: 18px;
      font-weight: 200;
      letter-spacing: -0.37px;
      line-height: 32px;
      color: $black;
      opacity: 0;
      margin-top: 8px;
      transition: all .3s ease-in-out;
      @media(max-width: 991px) {
        opacity: 1;
      }
      @media(max-width: 480px) {
        font-size: 14px;
        font-weight: 300;
        letter-spacing: -0.23px;
        line-height: 16px;
      }
    }

    img {
      width: 100%;
    }

    &:hover {
      text-decoration: none;

      .description {
        opacity: 1;
      }
    }
  }
}


.more-realizations {
  margin-top: 130px;
  margin-bottom: 80px;
  @media(max-width: 1580px) {
    margin-top: 180px;
  }
  @media(max-width: 991px) {
    margin-top: 130px;
  }
  @media(max-width: 480px) {
    margin-top: 120px;
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 30px;
    @media(max-width: 480px) {
      margin-bottom: 25px;
    }
  }

  .realizations-row {
    display: flex;
    margin: 0 -15px;
    @media(max-width: 767px) {
      margin: 0;
      flex-wrap: wrap;
    }

    & > a {
      width: 33.333%;
      padding: 0 15px;
      @media(max-width: 767px) {
        width: 100%;
        padding: 0;
        margin-bottom: 75px;
      }

      .photo {
        height: 200px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        @media(max-width: 767px) {
          margin: 0 -15px;
        }
      }

      .title {
        font-size: 18px;
        letter-spacing: -0.3px;
        line-height: 24px;
        color: $black;
        margin-top: 24px;
        font-weight: bold;
      }

      .desc {
        font-size: 16px;
        letter-spacing: -0.27px;
        line-height: 25px;
        opacity: 0;
        color: $dgrey;
        font-weight: 400;
        transition: all .3s ease-in-out;
        @media(max-width: 767px) {
          opacity: 1;
        }
      }

      &:hover {
        text-decoration: none;

        .desc {
          opacity: 1;
        }
      }

      &:last-of-type {
        @media(max-width: 767px) {
          margin-bottom: 0;
        }
      }
    }

  }
}

.seo-section {
  margin-top: 200px;
  @media (max-width: 991px) {
    margin-top: 120px;
  }
  @media (max-width: 480px) {
    margin-top: 70px;
  }
  & > div {
    display: flex;
    margin-left: -35px;
    margin-right: -35px;
    margin-bottom: 200px;
    @media(max-width: 991px) {
      flex-wrap: wrap;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 50px;
    }
  }

  .title-col {
    width: 40%;
    padding: 0 35px;
    @media(max-width: 991px) {
      width: 100%;
      padding: 0;
    }

    h2 {
      margin-bottom: 35px;
      line-height: 42px;
      @media(max-width: 1200px) {
        font-size: 30px;
        line-height: 45px;
      }
      @media(max-width: 991px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 25px;
      }
      @media(max-width: 480px) {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.62px;
        line-height: 26px;
        margin-bottom: 15px;
      }
    }

  }

  .text-col {
    width: 60%;
    padding: 0 35px;
    color: #1A1C22;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.45px;
    line-height: 39px;
    @media(max-width: 991px) {
      width: 100%;
      padding: 0;
      margin-top: 30px;
    }
    @media(max-width: 480px) {
      font-size: 14px;
      font-weight: 300;
      letter-spacing: -0.35px;
      line-height: 28px;
    }
  }
}

.single-category-wrapper {
  @media(max-width: 480px) {
    padding-top: 30px;
  }

  .single-category-intro {
    .category-title {
      font-size: 22px;
      font-weight: 600;
      letter-spacing: -0.31px;
      line-height: 46px;
      padding-top: 160px;
      @media(max-width: 767px) {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.36px;
        line-height: 23px;
        padding-top: 80px;
      }
      @media(max-width: 480px) {
        padding-top: 30px;
      }

      & > div {
        font-size: 90px;
        letter-spacing: -3px;
        line-height: 100px;
        font-weight: 600;
        color: transparent;
        text-shadow: none;
        -webkit-text-stroke: 1px #000;
        @media(max-width: 767px) {
          font-size: 30px;
          font-weight: bold;
          letter-spacing: -1.5px;
          line-height: 43px;
          margin-top: 0;
          -webkit-text-stroke: unset;
          color: $black;
        }
      }
    }

    .description {
      width: 60%;
      color: $black;
      margin-top: 35px;
      font-size: 20px;
      letter-spacing: -0.5px;
      line-height: 28px;
      @media(max-width: 767px) {
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: -0.5px;
        line-height: 32px;
        margin-top: 10px;
      }
    }
  }

  .realizations-wrapper {
    margin-top: 180px;
    @media(max-width: 767px) {
      margin-top: 75px;
    }

    & > div {
      &:nth-of-type(2n + 1) {
        .realization {
          .photo-col {
            order: 1;
          }

          .info-col {
            order: 2;
          }
        }
      }

      .realization {
        .photo-col {
          .photo {
            background-attachment: unset;
          }
        }
      }
    }
  }
}

.single-realization-section {
  .category-name {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.56px;
    line-height: 46px;
    padding-top: 30px;
    @media(max-width: 480px) {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.36px;
      line-height: 23px;
    }
  }

  h2 {
    width: 60%;
    @media(max-width: 991px) {
      width: 100%;
    }
  }

  .description {
    font-size: 20px;
    font-weight: 200;
    letter-spacing: -0.33px;
    line-height: 34px;
    width: 60%;
    color: $black;
    margin-top: 20px;
    @media(max-width: 991px) {
      margin-bottom: 50px;
      width: 100%;
    }
    @media(max-width: 480px) {
      width: auto;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: -0.23px;
      line-height: 24px;
      margin-bottom: 30px;
      margin-top: 10px;
    }
  }

  .video-box {
    margin-top: 130px;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    max-height: calc(100vh - 90px);
    @media (max-width: 1480px) {
      max-height: calc(100vh - 86px);
    }
    @media(max-width: 767px) {
      margin: 70px -15px 0 -15px;
    }
    @media(max-width: 480px) {
      margin: 30px -15px 0 -15px;
    }

    .photo {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all .3s ease-in-out;
        @media(max-width: 480px) {
          width: 70px;
        }
      }

      &:hover {
        img {
          transform: translate(-50%, -50%) scale(1.15);
        }
      }
    }

    iframe {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border: 0;
    }

    .prev, .next {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      border: 1.21px solid #E0E4E7;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: $black;
      @media(max-width: 1580px) {
        top: calc(100% + 25px);
        transform: translateY(0);
        width: auto;
        height: auto;
        border: 0;
      }

      i {
        @media(max-width: 1580px) {
          font-size: 16px;
          margin-right: 10px;
        }
        @media(max-width: 767px) {
          font-size: 12px;
          margin-right: 10px;
        }
      }

      span {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -0.53px;
        line-height: normal;
        @media(max-width: 991px) {
          font-size: 12px;
        }
        @media(min-width: 1580px) {
          display: none;
        }
      }

      &:hover {
        text-decoration: none;
      }
    }

    .prev {
      left: -140px;
      @media(max-width: 1580px) {
        left: 0;
      }
      @media(max-width: 767px) {
        left: 15px;
      }
    }

    .next {
      right: -140px;
      @media(max-width: 1580px) {
        right: 0;
      }
      @media(max-width: 767px) {
        right: 15px;
      }

      i {
        @media(max-width: 1580px) {
          margin-right: 0;
          margin-left: 10px;
        }
      }
    }
  }
}

.logo-section {
  position: relative;
  text-align: center;
  height: 480px;
  background: $yellow;
  @media(max-width: 991px) {
    height: 300px;
  }
  @media(max-width: 767px) {
    height: 180px;
  }

  .img-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 600px) {
      padding: 0 22px;
    }

    img {
      width: 586px;
      transform-style: preserve-3d;
      transform: perspective(1000px);
      @media(max-width: 600px) {
        width: 90%;
      }
    }
  }
}

.persons-section {
  margin-top: 250px;
  @media(max-width: 991px) {
    margin-top: 40px;
  }

  .person-box {
    display: flex;
    align-items: center;
    margin: 0 -30px 200px -30px;
    @media(max-width: 991px) {
      flex-wrap: wrap;
      justify-content: center;
      margin: 0;
      margin-bottom: 60px;
    }

    .photo-col {
      width: 40%;
      padding: 0 30px;
      @media(max-width: 1200px) {
        width: 50%;
      }
      @media(max-width: 991px) {
        text-align: center;
        padding: 0;
        width: 100%;
      }
      @media(max-width: 767px) {
        margin: 0 50px 30px;
      }


      img {
        max-width: 100%;
      }
    }

    .info-col {
      width: 60%;
      padding: 0 30px;
      @media(max-width: 1200px) {
        width: 60%;
      }
      @media(max-width: 991px) {
        text-align: center;
        padding: 0;
        width: 100%;
      }

      .name {
        color: $black;
        font-size: 40px;
        font-weight: bold;
        letter-spacing: -1.82px;
        line-height: 48px;
        @media(max-width: 1200px) {
          font-size: 32px;
          line-height: 40px;
        }
        @media(max-width: 767px) {
          font-size: 21.12px;
          font-weight: bold;
          letter-spacing: -0.96px;
          line-height: 25px;
        }
      }

      .role {
        color: #808599;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 2.5px;
        line-height: 25px;
        @media(max-width: 767px) {
          font-size: 8px;
          font-weight: 500;
          letter-spacing: 1.67px;
          line-height: 13.2px;
          margin-top: 5px;
        }
      }

      .social-box {
        margin-top: 24px;
        margin-bottom: 38px;
        display: flex;
        align-items: center;
        @media(max-width: 1200px) {
          margin-bottom: 25px;
        }
        @media(max-width: 991px) {
          justify-content: center;
        }

        a {
          width: 56px;
          height: 56px;
          border-radius: 50%;
          background-color: #F8F8F8;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 12px;
          color: $black;
          transition: all .3s ease-in-out;
          @media(max-width: 767px) {
            height: 42px;
            width: 42px;
          }

          i {
            font-size: 22px;

            &.icon-youtube {
              font-size: 24px;
            }
            &.icon-email{
              &:before{
                color: black;
              }
            }
          }

          &:hover {
            text-decoration: none;
            background: #EAEAEA;
          }
        }
      }

      .description {
        font-size: 18px;
        font-weight: 300;
        letter-spacing: -0.24px;
        line-height: 38px;
        color: $black;
        @media(max-width: 1200px) {
          font-size: 14px;
          letter-spacing: -0.5px;
          line-height: 28.24px;
        }
      }
    }
  }
}

.more-category-section {
  h2 {
    margin-bottom: 50px;
    @media(max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  .flex-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 767px) {
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    .prev, .next {
      @media(max-width: 767px) {
        width: 100%;
      }

      .button {
        background: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        color: $black;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        i {
          font-size: 16px;
          margin-right: 10px;
          display: inline-block;
          transform: rotate(270deg);
          @media(max-width: 767px) {
            font-size: 12px;
          }
        }

        span {
          font-size: 18px;
          font-weight: 500;
          letter-spacing: -0.8px;
          line-height: 20px;
          @media(max-width: 767px) {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: -0.53px;
            line-height: 26px;
          }
        }
      }

      .category-name {
        margin-top: 10px;
        -webkit-text-stroke: 1px $black;
        font-size: 60px;
        font-weight: bold;
        letter-spacing: -3px;
        line-height: 72px;
        color: transparent;
        position: relative;
        @media(max-width: 991px) {
          font-size: 45px;
          line-height: 55px;
        }
        @media(max-width: 767px) {
          font-size: 30px;
          font-weight: bold;
          letter-spacing: -1.5px;
          line-height: 36px;
          margin-top: 0;
        }

        &:after {
          content: attr(data-text);
          top: 0;
          left: 0;
          height: 0;
          width: 100%;
          color: $black;
          overflow: hidden;
          position: absolute;
          transition: all 0.9s;
        }
      }

      &:hover {
        .category-name {
          &:after {
            height: 100%;
          }
        }
      }
    }

    .next {
      text-align: right;
      @media(max-width: 767px) {
        text-align: left;
        margin-top: 35px;
      }

      .button {
        margin-left: auto;
        justify-content: flex-end;
        @media(max-width: 767px) {
          margin-left: 0;
          justify-content: flex-start;
        }

        i {
          margin-right: 0;
          margin-left: 10px;
          transform: rotate(90deg);
        }
      }
    }
  }
}

.social-section {
  margin-bottom: 330px;
  @media(max-width: 991px) {
    margin-bottom: 200px;
  }
  @media(max-width: 767px) {
    margin-bottom: 90px;
  }

  h2 {
    margin-bottom: 45px;
  }

  .social-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media(max-width: 767px) {
      flex-wrap: wrap;
    }

    a {
      position: relative;
      font-size: 60px;
      font-weight: bold;
      letter-spacing: -3px;
      line-height: 72px;
      color: transparent;
      text-shadow: none;
      -webkit-text-stroke: 1px $black;
      @media(max-width: 1200px) {
        font-size: 45px;
      }
      @media(max-width: 991px) {
        font-size: 38px;
        font-weight: bold;
        letter-spacing: -1.5px;
        line-height: 22px;
      }
      @media(max-width: 767px) {
        font-size: 30px;
        width: 100%;
        margin-bottom: 35px;
      }

      &:hover {
        text-decoration: none;

        &:after {
          width: 101%;
        }
      }

      &:after {
        content: attr(data-text);
        top: 0;
        left: 0;
        width: 0;
        color: $black;
        overflow: hidden;
        position: absolute;
        transition: all 0.9s;
      }

      &:last-of-type {
        @media(max-width: 767px) {
          margin-bottom: 0;
        }
      }
    }
    &.three-items {
      @media(min-width: 767px) {
        justify-content: space-between;
        a {
          width: auto;
        }
      }
    }
  }
  &.last-section{
    margin-bottom: 130px;
    @media(max-width: 767px){
      margin-bottom: 0;
    }
  }
}

.not-found-section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;

  h1 {
    @media(max-width: 480px) {
      font-size: 22px;
      margin-bottom: 5px;
    }
  }

  a {
    color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 480px) {
      font-size: 14px;

    }

    i {
      display: inline-block;
      transform: rotate(-90deg);
      margin-right: 15px;
      transition: all .3s ease-in-out;
    }

    &:hover {
      i {
        transform: rotate(-90deg) translateY(-10px);

      }
    }
  }
}

.packages-section {
  margin-top: 200px;
  @media (max-width: 991px) {
    margin-top: 120px;
  }
  @media (max-width: 480px) {
    margin-top: 70px;
  }
  h2 {
    margin-bottom: 70px;
    @media(max-width: 767px) {
      margin-bottom: 25px;
    }
  }

  .packages-row {
    display: flex;
    margin: 0 -15px;
    height: 100%;
    @media(max-width: 991px) {
      flex-wrap: wrap;
    }

    .package-col {
      padding: 0 15px;
      width: 33.333%;
      min-height: 560px;
      @media(max-width: 991px) {
        width: 50%;
        &:last-of-type {
          width: 100%;
          margin-top: 30px;
        }
      }
      @media(max-width: 767px) {
        width: 100%;
        margin-top: 15px;
        min-height: auto;
        &:first-of-type {
          margin-top: 0;
        }
        &:last-of-type {
          margin-top: 15px;
        }
      }

      .package {
        border: 1px solid $border;
        text-align: center;
        position: relative;
        padding: 45px 30px;
        display: flex;
        flex-direction: column;
        height: 100%;
        @media(max-width: 480px) {
          padding: 40px 20px;
          margin: 0 -15px;
        }

        .title {
          color: #1A202C;
          font-size: 28px;
          font-weight: bold;
          letter-spacing: -1.24px;
          line-height: 26px;
          margin-bottom: 8px;
        }

        .price {
          color: #1A202C;
          font-size: 18px;
          letter-spacing: -0.8px;
          line-height: 26px;
        }

        .divider {
          color: #1A1C22;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -0.35px;
          line-height: 32px;
          background: white;
          width: 100%;
          position: relative;
          margin: 28px 0 20px;

          span {
            background: white;
            padding: 0 15px;
            display: inline-block;
            z-index: 2;
            position: relative;
          }

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            height: 1px;
            background: $border;
            z-index: 1;
          }
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0 0 20px;

          li {
            color: #1A1C22;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: -0.35px;
            line-height: 20px;
            text-align: left;
            margin-bottom: 20px;
            position: relative;
            padding-left: 32px;
            @media(max-width: 767px) {
              font-size: 13px;
            }

            &:last-of-type {
              margin-bottom: 0;
            }

            strong {
              margin-left: 5px;
            }

            &:before {
              content: "\e90c";
              position: absolute;
              left: 0;
              top: 0;
              font-family: lemon;
              margin-right: 15px;
              color: #808599;
              font-size: 10px;
            }
          }
        }

        .button-place {
          margin: auto auto 0 auto;
          height: 46px;
          @media(max-width: 767px) {
            margin-top: 30px;
          }

          &.grey {
            .translating-button.with-bg:after {
              background: #EFEFEF;
            }
          }

          .translating-button.with-bg:after {
            background: #CBE3F2;
          }
        }

        &.premium {
          border-top: 0;
          box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
          border: 0;

          &:before {
            content: "";
            position: absolute;
            left: -1px;
            right: -1px;
            top: 0;
            height: 8px;
            background: #B0D6EC;
          }
        }
      }

      &:nth-of-type(2) {
        @media(max-width: 480px) {
          margin: 0;
        }

        .package {
          @media(max-width: 480px) {
            border: 0;
          }
        }
      }
      &:last-of-type{
        @media(max-width: 480px){
          margin-top: 0;
        }
      }
    }
  }
}

.extra-services-section {
  margin-top: 250px;
  @media (max-width: 991px) {
    margin-top: 120px;
  }
  @media (max-width: 480px) {
    margin-top: 70px;
  }

  .flex-row {
    display: flex;
    justify-content: space-between;
    @media(max-width: 991px) {
      flex-wrap: wrap;
    }

    .left-col {
      @media(max-width: 991px) {
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 80px;
      }
      @media(max-width: 480px) {
        margin-bottom: 40px;
      }

      .desc {
        font-size: 18px;
        letter-spacing: -0.45px;
        line-height: 28px;
        margin-top: 20px;
        font-weight: 300;
        color: $black;
        @media(max-width: 991px) {
          br {
            display: none;
          }
        }
        @media(max-width: 480px) {
          font-size: 0.875rem;
          margin-top: 5px;
          br {
            display: block;
          }
        }
      }
    }

    .right-col {
      min-width: 580px;
      @media(max-width: 1200px) {
        min-width: auto;
      }
      @media(max-width: 991px) {
        width: 100%;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          border-bottom: 1px solid $border;
          padding: 22px 0;
          @media(max-width: 480px) {
            padding: 20px 0;
          }

          .label {
            font-weight: 300;
            font-size: 18px;
            letter-spacing: -0.45px;
            line-height: 28px;
            color: $black;
            @media(max-width: 1200px) {
              margin-right: 50px;
            }
            @media(max-width: 991px) {
              margin-right: 20px;
            }
            @media(max-width: 480px) {
              font-size: 0.875rem;
              line-height: 20px;
            }
          }

          .value {
            font-size: 18px;
            text-align: right;
            color: $black;
            @media(max-width: 480px) {
              font-size: 0.875rem;
              white-space: nowrap;
            }
          }

          &:first-of-type {
            padding-top: 0;
          }

          &:last-of-type {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.quote-section {
  margin-top: 250px;
  @media (max-width: 991px) {
    margin-top: 120px;
  }
  @media (max-width: 480px) {
    margin-top: 70px;
  }

  .slider-section {
    & > .container {
      text-align: center;

      img {
        margin: 70px auto 40px auto;
        @media(max-width: 480px) {
          width: 70px;
          margin: 40px auto 20px auto;
        }
      }
    }

    .swiper-pagination {
      bottom: 0;

      .swiper-pagination-bullet {
        width: 11px;
        height: 11px;
        background: #E0E4E7;
        margin-right: 8px;
        opacity: 1;

        &:last-of-type {
          margin-right: 0;
        }

        &.swiper-pagination-bullet-active {
          background: #B8D5EA;
        }
      }
    }

    .swiper-wrapper {
      .swiper-slide {
        text-align: center;

        .desc {
          width: 970px;
          font-size: 24px;
          letter-spacing: -1.07px;
          line-height: 51px;
          color: $black;
          margin: 0 auto 40px auto;
          font-weight: 300;
          @media(max-width: 1200px) {
            width: 100%;
          }
          @media(max-width: 991px) {
            font-size: 18px;
            line-height: 38px;
          }
          @media(max-width: 480px) {
            font-size: 16px;
            line-height: 38px;
          }
        }

        .person, .extra-txt {
          color: $black;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: -0.8px;
          margin-bottom: 10px;
          @media(max-width: 480px) {
            font-size: 16px;
            margin-bottom: 0;
          }
        }

        .extra-txt {
          font-weight: 300;
          margin-bottom: 100px;
        }
      }
    }
  }
}
