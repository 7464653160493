.hamburger-btn {
  @media(min-width: 767px) {
    display: none;
  }
  line-height: 36px;
  position: relative;
  height: 34px;
  width: 34px;
  top: 1.5px;

  label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: 95px;
      pointer-events: none;
    }
  }



  path {
    fill: none;
    stroke: $black;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    --length: 24;
    --offset: -38;
    stroke-dasharray: var(--length) var(--total-length);
    stroke-dashoffset: var(--offset);
    transition: all .8s cubic-bezier(.645, .045, .355, 1);
  }

  input {
    opacity: 0;
  }

  circle {
    fill: #fff3;
    opacity: 0;
  }

  label:hover circle{
    opacity: 1;
  }

  input:checked + svg, input.active + svg  {
    .line--1,
    .line--3 {
      --length: 22.627416998;
    }

    .line--2 {
      --length: 0;
    }
  }


  .line--1,
  .line--3 {
    --total-length: 126.64183044433594;
  }

  .line--2 {
    --total-length: 70;
  }

  input:checked + svg, input.active + svg {
    .line--1,
    .line--3 {
      --offset: -95.114919;
    }

    .line--2 {
      --offset: -49;
    }
  }
}
