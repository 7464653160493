footer {
  background: $yellow;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 160px 0;
  position: static;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 100vh;
  @media(max-width: 991px) {
    padding: 90px 0;
    max-height: none;
  }
  @media(max-width: 767px) {
    padding: 50px 0 25px;
  }

  .main-content {
    display: flex;
    @media(max-width: 991px) {
      flex-wrap: wrap;
      & > div {
        width: 100%;
      }
    }

    .left-side {
      .lemon-sign {
        width: 235px;
        display: block;
        @media(max-width: 767px){
          display: none;
        }

        img {
          max-width: 100%;
        }
      }

      h1 {
        font-size: 68px;
        font-weight: 600;
        letter-spacing: -3.7px;
        line-height: 74px;
        margin: 25px 0 50px;
        @media(max-width: 1200px) {
          font-size: 50px;
          line-height: 60px;
        }
        @media(max-width: 767px) {
          font-size: 28px;
          font-weight: bold;
          letter-spacing: -1.87px;
          line-height: 36px;
        }
        @media(max-width: 480px){
          margin-bottom: 15px;
        }
      }

      .contact-button {
        @extend .underline-button;
        font-size: 22px;
        letter-spacing: 0;
        line-height: 33px;
        @media(max-width: 991px) {
          margin-bottom: 60px;
        }
        @media(max-width: 767px) {
          font-size: 12.8px;
          font-weight: bold;
          letter-spacing: -0.48px;
          line-height: 15px;
          margin-bottom: 45px;
        }

        &:after {
          background: $black;
          @media(max-width: 767px) {
            height: 1px;
          }
        }
      }
    }

    .title {
      font-size: 12px;
      line-height: 25px;
      font-weight: 500;
      margin-bottom: 3px;
      color: $black;
      @media(max-width: 767px) {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 25px;
      }
    }

    .menu {
      margin-left: auto;
      margin-right: 120px;
      @media(max-width: 991px) {
        margin-right: 0;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(26, 28, 34, 0.13);
      }

      .title {
        margin-bottom: 14px;
      }

      ul {
        list-style: none;
        padding: 0;
        @media(max-width: 991px) {
          display: flex;
          margin-bottom: 25px;
        }

        li {
          margin-bottom: 16px;
          @media(max-width: 991px) {
            margin: 0 45px 0 0;
          }
          @media(max-width: 480px) {
            font-size: 14px;
            font-weight: bold;
            letter-spacing: -0.78px;
          }

          a.translating-button {
            color: $black;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: -0.4px;
            text-align: left;
            align-items: flex-start;
            em span{
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .bottom-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 160px;
    @media(max-width: 991px) {
      margin-top: 100px;
    }
    @media(max-width: 767px) {
      margin-top: 40px;
    }
    @media(max-width: 480px) {
      margin-top: 30px;
    }

    .social-box {
      display: flex;
      align-items: center;

      & > a {
        line-height: 20px;
        margin-right: 20px;
        color: $black;
        font-size: 18px;
        transition: all .3s ease-in-out;

        &:hover {
          text-decoration: none;
          transform: scale(1.2);
        }

        &:first-of-type {
          font-size: 24px;
        }
      }
    }

    .go-up {
      background: transparent;
      border: 0;
      margin: 0;
      padding: 0;
      font-size: 25px;
      transition: all .3s ease-in-out;
      @media (max-width: 480px) {
        font-size: 20px;
      }
      &:hover{
        transform: translateY(-5px);
      }
    }
  }
}

.address {
  & > div {
    border-bottom: 1px solid rgba(#1A1C22, 0.13);
    padding: 20px 0;

    .value {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: -0.88px;
      line-height: 25px;

      a, + .see-on-map {
        color: $black;
        display: inline-flex;
        position: relative;
        &:after{
          content: "";
          position: absolute;
          top: calc(100% - 5px);
          left: 50%;
          transform: translateX(-50%);
          height: 2px;
          background: $black;
          width: 0;
          transition: all .3s ease-in-out;
        }
        &:hover{
          &:after{
            width: 100%;
          }
        }
      }
    }

    .see-on-map {
      background: transparent;
      border: 0;
      padding: 0;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.5px;
      line-height: 27px;
      margin-top: 8px;
      color: $black;

      .icon {
        font-size: 18px;
        margin-right: 5px;
      }
    }

    &:first-of-type {
      padding-top: 0;

      .title {
        margin-bottom: 17px;
        @media(max-width: 767px) {
          margin-bottom: 5px;
        }
      }
    }

    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
}
